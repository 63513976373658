import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sites--search"
export default class extends Controller {
  select(e) {
    e.preventDefault();
    const siteId = parseInt(e.target.dataset.site, 10);
    this.dispatch("select", { detail: { siteId } });
  }

  assignFrc(e) {
    e.preventDefault();
    const siteId = parseInt(e.target.dataset.site, 10);
    const frcSelect = e.target.parentNode.querySelector("select");
    const frcTypeId = parseInt(frcSelect.value, 10);
    const frcTypeName = frcSelect.selectedOptions[0].textContent;
    const siteName = e.target.dataset.sitename;
    const siteState = e.target.dataset.sitestate;
    this.dispatch("assignFrc", {
      detail: { frcTypeId, siteId, frcTypeName, siteName, siteState },
    });
  }

  assignUst(e) {
    e.preventDefault();
    const siteId = parseInt(e.target.dataset.site, 10);
    const siteName = e.target.dataset.sitename;
    const siteState = e.target.dataset.sitestate;
    const ustSelect = e.target.parentNode.querySelector("select");
    const ustId = parseInt(ustSelect.value, 10);
    const ustName = ustSelect.selectedOptions[0].textContent;

    this.dispatch("assignUst", {
      detail: { ustId, siteId, siteName, ustName, siteState },
    });
  }
}
