import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="attachments--inline-edit"
export default class extends Controller {
  enableEditName(e) {
    const container = e.target.closest(".attachment-show");
    const form = container.querySelector(".attachment__inline-name-form");
    const name = container.querySelector(".attachment__name");

    form.classList.remove("hidden");
    name.classList.add("hidden");
  }

  async submitFormName(e) {
    this.disableEditName(e);
  }

  cancelEditName(e) {
    e.preventDefault();
    this.disableEditName(e);
  }

  disableEditName(e) {
    const container = e.target.closest(".attachment-show");
    const form = container.querySelector(".attachment__inline-name-form");
    const name = container.querySelector(".attachment__name");

    form.classList.add("hidden");
    name.classList.remove("hidden");
  }

  enableEditComments(e) {
    const container = e.target.closest(".attachment-show");
    const form = container.querySelector(".attachment__inline-comments-form");
    const name = container.querySelector(".attachment__comments");

    form.classList.remove("hidden");
    name.classList.add("hidden");
  }

  async submitFormComments(e) {
    this.disableEditComments(e);
  }

  cancelEditComments(e) {
    e.preventDefault();
    this.disableEditComments(e);
  }

  disableEditComments(e) {
    const container = e.target.closest(".attachment-show");
    const form = container.querySelector(".attachment__inline-comments-form");
    const name = container.querySelector(".attachment__comments");

    form.classList.add("hidden");
    name.classList.remove("hidden");
  }
}
