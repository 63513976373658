// app/javascript/controllers/dropzone_controller.js
import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";

export default class extends Controller {
  static values = {
    siteId: String,
  };

  connect() {
    const dropzoneConfig = {
      url: this.url,
      method: "post",
      clickable: true,
      paramName: "upload_data",
      maxFilesize: 256,
      parallelUploads: "5",
      addRemoveLinks: true,
      acceptedFiles: ".doc, .pdf, .jpg, .jpeg, .png",
      previewTemplate: `<div>
        <p data-dz-name style="display: none;"></p>
        <p data-dz-size style="display: none;"></p>
        <img data-dz-thumbnail style="display: none;" />
        <div data-dz-uploadprogress style="display: none;"></div>
        <div data-dz-errormessage style="display: none;"></div>
      </div>`,
      maxFiles: 5,
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
        Accept: "text/vnd.turbo-stream.html",
        "X-Requested-With": "XMLHttpRequest",
      },

      init: function () {
        this.on("success", function (file, response) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(response, "text/html");
          const turboStreamElements = doc.querySelectorAll("turbo-stream");

          turboStreamElements.forEach((element) => {
            Turbo.renderStreamMessage(element.outerHTML);
          });
        });
      },

      removedfile: function (file) {
        if (file.serverId) {
          $.ajax({
            type: "DELETE",
            url: "/uploads/" + file.serverId,
            success: function (data) {
              file.previewElement.remove();
            },
            error: function (xhr, status, error) {
              console.log("Error: " + error);
            },
          });
        } else {
          file.previewElement.remove();
        }
      },
    };

    this.dropzone = new Dropzone(this.element, dropzoneConfig);
  }

  get url() {
    return `/sites/${this.siteIdValue}/attachments`;
  }
}
