import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "spcc",
    "ust",
    "third",
    "additional",
    "tier",
    "permit"
  ]

  connect() {
    this.addEventListeners()
  }

  addEventListeners() {
    // Add listeners for all static targets
    this.constructor.targets.forEach(targetName => {
      if (this[`${targetName}Target`]) {
        if (targetName === 'ust') {
          // For UST, only add dragstart listener
          this[`${targetName}Target`].addEventListener("dragstart", this.dragStart.bind(this))
        } else {
          // For other targets, add all listeners
          this.addListenersToTarget(this[`${targetName}Target`])
        }
      }
    })

    // Add listeners for dynamic targets
    const dynamicTargets = ["doc", "photo"]
    dynamicTargets.forEach(targetName => {
      const target = this.element.querySelector(`[data-attachment-drag-target="${targetName}"]`)
      if (target) {
        this.addListenersToTarget(target)
      }
    })
  }

  addListenersToTarget(target) {
    target.addEventListener("dragover", this.allowDrop.bind(this))
    target.addEventListener("drop", this.dropEvent.bind(this))
    target.addEventListener("dragstart", this.dragStart.bind(this))
  }

  allowDrop(event) {
    event.preventDefault()
  }

  dragStart(event) {
    const attachmentElement = event.target.closest('[data-attachment-id]')
    if (attachmentElement) {
      const attachmentId = attachmentElement.dataset.attachmentId
      event.dataTransfer.setData("attachmentId", attachmentId)
    } else {
      console.error("No attachment ID found for dragged element")
    }
  }

  dropEvent(event) {
    event.preventDefault()
    event.stopPropagation()

    const attachmentId = event.dataTransfer.getData("attachmentId")
    if (!attachmentId) {
      console.error("No attachment ID found in drop event")
      return
    }

    const targetSection = event.currentTarget.dataset.attachmentDragTarget
    this.moveAttachment(attachmentId, targetSection)
  }

  moveAttachment(attachmentId, targetSection) {
    if (!attachmentId) {
      console.error("No attachment ID provided")
      return
    }

    fetch(`/attachments/${attachmentId}/move`, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": this.getMetaValue("csrf-token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ target_section: targetSection }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        return response.text()
      })
      .then((html) => {
        this.element.innerHTML = html
      })
      .catch((error) => {
        console.error("Error moving attachment:", error)
      })
  }

  getMetaValue(name) {
    const element = document.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }

  disconnect() {
    // Clean up event listeners if necessary
  }
}